import { useMemo } from "react"

const OFFLOADED_HOST = "space.dekoder.org"

export function useOffloadedImages(active, imgProps) {
  return useMemo(() => {
    if (!active) return imgProps
    return imgProps.map((p) => ({
          ...p,
          src: replaceUrlHost(p.src, OFFLOADED_HOST),
        }))
  }, [active, imgProps])
}

function replaceUrlHost(url, newHost) {
  let newUrl = new URL(url)
  newUrl.hostname = newHost
  return newUrl.href
}
