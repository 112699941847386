import React, { useEffect, useState } from "react"

const Figure = ({ className, img, figcaption }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    document.body.classList.toggle("overflow-y-hidden", expanded)
    const onClick = (ev) => ev.keyCode === 27 && setExpanded(false)
    window.addEventListener("keydown", onClick)
    return () => window.removeEventListener("keydown", onClick)
  }, [expanded])

  const orient =
    img.props.width / img.props.height <= 1 ? "is-portrait" : "is-landscape"

  return (
    <figure
      className={`figure ${className} expandable-image
        ${expanded ? "expanded" : "collapsed"}
        ${orient}`}
    >
      <button
        className={"outline-none focus:outline-none"}
        onClick={() => setExpanded((exp) => !exp)}
        aria-label="expand"
      >
        <div
          className={
            "figure__image-wrapper flex flex-col items-center justify-center"
          }
        >
          <span
            className={`${orient} flex items-center justify-center relative text-white text-md`}
          >
            {img}
          </span>
        </div>
      </button>
      {figcaption}
    </figure>
  )
}

export default Figure
