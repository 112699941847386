import styled from "styled-components"

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, ${props => props.active ? 0.5 : 0});
  transition: .3s ease-in-out;
  pointer-events: ${props => props.active ? "auto" : "none"};
`

export default BackgroundOverlay
