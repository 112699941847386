import React, { useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"

const FN_TEXT = {
  de: "Fußnoten",
  ru: "Сноски"
}
const FootnoteHeader = () => {
  const lang = useContext(LangContext)
  return (
    <div className={'my-8'}>
      <strong className={'uppercase'}>
        {FN_TEXT[lang.id]}
      </strong>
    </div>
  )
}

const Footnote = ({ children, footnoteId }) => {
  const isFirst = footnoteId === 1
  const onUpClick = (e) => {
    e.preventDefault()
    scrollTo(`#footnote-link-${footnoteId}`)
  }
  return (
    <div className={'footnote mb-8'}>
      {isFirst && <FootnoteHeader />}
      <div className={'flex'} id={`footnote-${footnoteId}`}>
        <div className={'mr-8'}>
          <button className={'font-display text-sm'} onClick={onUpClick}> {footnoteId} </button>
        </div>
        <div className={'text-sm'}>{children}</div>
      </div>
    </div>
  )
}

export default Footnote

export function scrollTo(elSelector) {
  const targetEl = document.querySelector(elSelector)
  if (!targetEl) {
    console.error(`Element ${elSelector} not found.`)
    return
  }
  scrollIntoView(targetEl, { time: 300 })
}
