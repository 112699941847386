import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import "./menu.scss"
import MenuItem from "./menu-item"
import { LangContext, WebpContext, NO_WEBP } from "gatsby-source-dek-wp"

const MUDDLE_SRC = "https://specials.dekoder.org/content/uploads/2021/12/muddle-small.png"
// const MUDDLE_SRC = "https://specials.dekoder.org/content/uploads/2021/05/muddle.png"

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function (p) {
  return this.slice(0).sort(function (a, b) {
    return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0
  })
}

const Menu = ({ allLangs, metaData, contents, selected = {} }) => {
  const topDistance = 48
  const ref = useRef()
  const langId = useContext(LangContext).id
  const [menuOpen, setMenuOpen] = useState(false)

  const noWebpSupport = useContext(WebpContext)

  const onClick = (ev) => {
    if (ev.target.checked) {
      setMenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    const onScroll = (ev) => {
      let y = window.scrollY

      if (y >= topDistance) {
        ref.current.classList.add("is-fixed")
      } else {
        ref.current.classList.remove("is-fixed")
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  const mainPages = contents.filter(function (el) {
    return el.pageType === "default"
  })

  const longreadPages = contents.filter(function (el) {
    return el.pageType === "longread"
  })

  const pagesSorted = mainPages.sortBy("orderId")
  const longreadPagesSorted = longreadPages.sortBy("orderId")

  const name = metaData.metaInfos.find((m) => m.langId === langId)?.name ?? ""
  const title = metaData.metaInfos.find((m) => m.langId === langId)?.title ?? ""

  return (
    <div className={"menu w-full h-44 -mb-44"}>
      <input
        className={"menu__trigger fixed top-0 z-50 hidden"}
        id="menu"
        type="checkbox"
        onChange={onClick}
      />

      <div
        className={
          "menu__head bg-black tracking-wider text-base font-display text-white flex justify-between px-4 md:px-6 fixed md:relative top-0 z-20 w-full h-12"
        }
      >
        <div className={"flex flex-col flex-1 justify-center"}>
          <Link
            className={"shake-trigger menu__home-link flex flex-1 items-center"}
            to={`/${langId}`}
            target="_self"
            rel="noopener noreferrer"
          >
            <img
              className={"w-8 h-8 mr-4 filter invert"}
              src={MUDDLE_SRC + (noWebpSupport ? `?${NO_WEBP}` : "")}
              alt="Home"
            />
            <div
              className={
                selected.slug ? "hidden md:flex shake-rotate z-1" : "hidden"
              }
            >
              {title}
            </div>
          </Link>
        </div>

        <div className={"flex flex-col justify-center items-center flex-1"}>
          <a target="_blank" href="https://www.dekoder.org" rel="noreferrer">
            {name}
          </a>
        </div>
        <div
          className={"menu__label-wrapper flex justify-end items-center flex-1"}
        >
          <div
            className={
              "flex flex-col justify-center mr-4 md:mr-0 uppercase w-full"
            }
          >
            <ul className={"menu__langlist shake-trigger"}>
              <li
                className={
                  "menu__langlist--selected shake-trigger flex flex-col justify-center"
                }
              >
                <span className={"-mb-0.5"}>{langId}</span>
                <ul className={"menu__langlist-select shake-rotate flex"}>
                  {allLangs.map((l, i) => {
                    // link to current content translation || /de , /ru etc
                    const translOfSlctd = (selected?.translations || []).find(
                      (t) => t.langId === l.id
                    )
                    const dest = translOfSlctd ? translOfSlctd.slug : l.id
                    return (
                      <li key={i}>
                        <Link key={i} to={`/${dest}`}>
                          {l.id}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </ul>
          </div>

          <label
            className={
              "menu__label md:hidden cursor-pointer w-10 flex items-center justify-center -mb-0.5"
            }
            htmlFor="menu"
          >
            <span className={"menu__label--bars"}>
              <i className="fas fa-bars" />
            </span>
            <span className={"menu__label--times"}>
              <i className="fas fa-times" />
            </span>
          </label>
        </div>
      </div>

      <div
        ref={ref}
        className={
          "menu__list-wrapper w-10/12 md:w-full fixed md:relative top-0 z-10 md:pt-0 md:h-30 md:-mt-4 md:mix-blend-normal"
        }
      >
        {/*change to fixed after scroll */}
        <ul
          className={
            "menu__list w-10/12 md:w-full md:h-30 md:flex xl:container xl:mx-auto font-display text-lg md:text-md p-4 md:p-0 justify-start pt-32 md:pt-0 md:justify-start items-start md:items-center md:relative"
          }
        >
          {pagesSorted.map((p, i) => {
            return <MenuItem key={i} p={p} i={i} selected={selected} />
          })}

          <li className={"mb-8 md:mb-0 md:ml-auto"}>{""}</li>

          {longreadPagesSorted.map((p, i) => {
            return <MenuItem key={i} p={p} i={i} selected={selected} />
          })}
        </ul>
      </div>
    </div>
  )
}

export default Menu
