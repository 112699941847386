import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import MaisonBoldWoff2 from "../../fonts/Maison-Bold.woff2"
// import FactorA from "../../fonts/factor-a-variable.ttf"
import muddle from "../../images/muddle.png"
import { metaInfos } from "../../../meta-data-node"

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Maison Bold';
    src: url(${MaisonBoldWoff2}) format('woff2');

    font-weight: 600;
    font-style: normal;
  }
`

const SocialImageWrapper = styled.div`
  width: 1200px;
  height: 630px;
  margin: 0 auto;
  background-color: white;
  background-image: url(${(p) => p.img});
  background-size: cover;
  position: relative;
  overflow: hidden;
`

const Muddle = styled.div`
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 630px;
  height: 630px;
  color: white;
  > * {
    display: block;
    text-align: center;
    width: 450px;
    font-weight: 600;
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
  }
  h1 {
    font-size: 76px;
    line-height: 76px;
    letter-spacing: -0.01em;
    font-family: "Maison Bold", "HelveticaBold", Arial, sans-serif;
    margin-bottom: 0;
    hyphens: manual;
    width: auto;
  }
  h4 {
    size: 30px;
    line-height: 33px;
    font-family: "Maison Bold", "HelveticaBold", Arial, sans-serif;
  }
  background-image: url(${muddle});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  letter-spacing: 0.01em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  div {
    position: absolute;
    top: 10px;
    font-size: 20px;
    line-height: 110%;
    font-weight: 600;
    font-family: "Maison Bold", "HelveticaBold", Arial, sans-serif;
  }
`

const defaultImg = "https://specials.dekoder.org/content/uploads/2021/05/0097.jpg"

const SocialImage = ({ pageContext = {} }) => {
  const { title, titleVideoPoster, menuTitle, langId } = pageContext
  console.log("RENDERING SOCIAL IMG", title, titleVideoPoster)
  const siteTitle = metaInfos.find((m) => m.langId === langId)?.title ?? ""
  const siteSubtitle = metaInfos.find((m) => m.langId === langId)?.subtitle ?? ""
  return (
    <>
      <Fonts />
      <SocialImageWrapper img={titleVideoPoster || defaultImg}>
        <Header>
          <div style={{ left: "14px" }}>
            дekóder special
          </div>
          <div style={{ left: "1007px" }}>
            {siteTitle} –<br/>
            {siteSubtitle}
          </div>
        </Header>
        <Muddle>
          <div className="title-wrapper"><h1>{title}</h1></div>
          {!!menuTitle && <h4>{menuTitle}</h4>}
        </Muddle>
      </SocialImageWrapper>
    </>
  )
}

export default SocialImage
