import { WebpContext } from "gatsby-source-dek-wp"
import { useMemo, useState, useEffect, useContext } from "react"
import { theme } from "../../../tailwind.config"

const breakpointMobile = theme.screens.md.replace("px", "") * 1

const SCROLL_PX_PER_FRAME = 40 // sets the playback speed; higher value = slower
const N_MOBILE = 2 // load only every n-th frame
const N_DESKTOP = 1

// const MIN_WIDTH_MOBILE = 400 // 768
const MIN_WIDTH_DESKTOP = 900 // 1200
const MOBILE_SRC_SUFFIX = "-400x400"

export function useTrafficSaver(imgProps) {
  const isMobile = useMobileState()
  const n = isMobile ? N_MOBILE : N_DESKTOP
  const [reducedImgProps, scrollPxPerFrame] = useReducedImgArray(imgProps, n)
  const responsiveImgProps = useSrcSets(reducedImgProps, isMobile)
  const webpImgProps = useWebpSupport(responsiveImgProps)
  return [webpImgProps, scrollPxPerFrame, n]
}

function useMobileState() {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth < breakpointMobile)
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])
  return isMobile
}

function useReducedImgArray(imgProps = [], n = 1) {
  // use only every n-th image
  const reducedImgProps = useMemo(
    () => imgProps.filter((_, i) => !(i % n)),
    [imgProps, n]
  )
  // console.log("totalFrames:", newImgProps.length)
  const scrollPxPerFrame = useMemo(() => SCROLL_PX_PER_FRAME * n, [n])
  return [reducedImgProps, scrollPxPerFrame]
}

function useSrcSets(imgProps, isMobile) {
  const responsiveImgProps = useMemo(() => {
    return imgProps.map((p) => ({
      ...p,
      src: isMobile
        ? p.src.replace(/(\.jpe?g)$/, (_, p1) => `${MOBILE_SRC_SUFFIX}${p1}`)
        : getResponsiveSrc(p.srcSet, MIN_WIDTH_DESKTOP) || p.src
    }))
    // return imgProps.map(p => ({ ...p, src: getResponsiveSrc(p.srcSet) || p.src }))
  }, [imgProps, isMobile])
  return responsiveImgProps
}

// "https://specials.dekoder.org/content/uploads/2021/06/0000-7.jpg 900w, https://specials.dekoder.org/content/uploads/2021/06/0000-7-300x169.jpg 300w, https://specials.dekoder.org/content/uploads/2021/06/0000-7-768x432.jpg 768w"

function getResponsiveSrc(srcSet = "", minWidth = 768) {
  if (!srcSet) return
  const matchSources = srcSet
    .split(",")
    .map((pairStr) => pairStr.trim().split(" ")) // ["https://specials.dekoder.org/content/uploads/2021/12/02-ilylle_objekt00001.jpg", "1200w"]
    .map(([src, widthStr]) => ({ src, width: widthStr.slice(0, -1) * 1 })) // to objects
    .filter((s) => s.width >= minWidth)
    .sort((a, b) => a.width - b.width)
  return matchSources.length ? matchSources[0].src : null
}

function useWebpSupport(imgProps) {
  const noWebpSupport = useContext(WebpContext)
  // const srcSuffix = noWebpSupport ? `?${NO_WEBP}` : WEBP_SUFFIX
  return useMemo(
    () => imgProps.map((p) => ({ ...p, src: p.src
      .replace(".jpg", noWebpSupport ? ".jpg" : ".jpg.webp")
    })),
    [imgProps, noWebpSupport]
  )
}

// srcSet example
// "https://specials.dekoder.org/content/uploads/2021/12/02-ilylle_objekt00001.jpg 1200w,
// https://specials.dekoder.org/content/uploads/2021/12/02-ilylle_objekt00001-300x169.jpg 300w,
// https://specials.dekoder.org/content/uploads/2021/12/02-ilylle_objekt00001-768x432.jpg 768w"

/* function useTestButtons() {
  const [n, setN] = useState(1)
  const values = [1, 2, 3]
  const buttons = (
    <div style={{ position: "absolute", left: "30px", top: "20vh", zIndex: 10 }}>
      n = {n}
      <br />
      {values.map((v) => (
        <button key={v} onClick={() => setN(v)} style={{ border: "1px solid black", marginRight: "1em", padding: "0 .5em" }}>
          {v}
        </button>
      ))}
    </div>
  )
  return [n, buttons]
} */
