import React, { createRef, useEffect, useState, useMemo } from "react"
import { useIntersectionObserver } from "gatsby-source-dek-wp"
import cyrillicToTranslit from "cyrillic-to-translit-js"

const isH2 = (comp) => comp?.type === "h2"

export function useH2Subheadings(children) {
  const [currH2Index, setCurrH2Index] = useState(-1)
  const newChildren = useMemo(() => {
    const childArray = React.Children.toArray(children)
    return childArray
      .flatMap((c) => c)
      .reduce(
        (acc, comp) => {
          // equip all h2s with refs & IntersectionObservers, leave all other components as they are
          const returnComp = isH2(comp)
            ? addRefsToH2s(comp, acc.h2Index, setCurrH2Index)
            : comp
          return {
            components: [...acc.components, returnComp],
            h2Index: isH2(comp) ? acc.h2Index + 1 : acc.h2Index,
          }
        },
        { components: [], h2Index: 0 }
      ).components
  }, [children])
  const subheadings = newChildren.filter((c) => c?.props?._isSubheading)
  return [newChildren, subheadings, currH2Index]
}

const H2 = React.forwardRef((props, ref) => {
  const { children, _onEnter, _onUpscrollLeave, _isSubheading, ...otherProps } = props
  const [, inView, direction] = useIntersectionObserver(
    {
      // observe only top 60%
      rootMargin: "0px 0px -40% 0px",
    },
    ref
  )
  useEffect(() => {
    // console.log("IN VIEW", inView, direction, props.children)
    if (inView) _onEnter()
    else if (!inView && direction === "up") _onUpscrollLeave()
  }, [inView, direction, _onEnter, _onUpscrollLeave])
  return (
    <h2 {...otherProps} ref={ref}>
      {children}
    </h2>
  )
})

function addRefsToH2s(comp, h2Index, setCurrH2Index) {
  const ref = createRef()
  const innerText = getInnerText(comp)
  const id = getAnchorName(innerText)
  return (
    <H2
      key={id}
      id={id}
      ref={ref}
      {...comp.props}
      _onEnter={() => setCurrH2Index(h2Index)}
      _onUpscrollLeave={() => setCurrH2Index(h2Index - 1)}
      _isSubheading={true}
    />
  )
}

function getInnerText(comp) {
  const { children } = comp.props
  return Array.isArray(children)
    ? children.map(getInnerText)
    : typeof children === "object"
    ? getInnerText(children)
    : `${children}`
}

function getAnchorName(text = "") {
  if (typeof text !== "string") return ""
  const safeText = cyrillicToTranslit().transform(text)
  return safeText
    .replace(/[^a-z0-9 ]+/gi, "")
    .toLowerCase()
    .replace(/ /g, "-")
}
