import { useState, useEffect } from "react"

export function useImagePreload(imgProps, active) {
  const [loadCount, setLoadCount] = useState(0)
  useEffect(() => {
    if (!active) return
    imgProps.forEach((p, i) => {
      const img = new Image()
      img.src = p.src
      img.onload = () => setLoadCount(c => c + 1)
    })
  }, [imgProps, active])
  const loadPercent = Math.round(loadCount / imgProps.length * 1000) / 10
  return loadPercent
}