import React, {createContext, useContext, useState} from "react"
import BackgroundOverlay from "./background-overlay"
import { createPortal } from "react-dom"

export const AnnotationsContext = createContext(undefined)

// Wrapper for article component to provide annotation state as context
export function withAnnotations(Comp) {
  return (props) => {
    const state = useState()
    const [shownAnnotationId, setShownAnnotationId] = state
    const hasActiveAnnotation = !!shownAnnotationId
    const close = () => setShownAnnotationId(null)
    return (
      <AnnotationsContext.Provider value={state}>
        <Comp {...props} />
        <BackgroundOverlay active={hasActiveAnnotation} onClick={close}/>
      </AnnotationsContext.Provider>
    )
  }
}

const annotationStyle = {
}

const Annotation = ({children, annotationId}) => {
  const [shownAnnotationId, setShownAnnotationId] = useContext(AnnotationsContext)
  const close = () => setShownAnnotationId(null)
  const isActive = annotationId === shownAnnotationId
  if (!isActive) return null
  return (
    <div className={'annotation fixed z-50 left-0 top-0 bottom-0 right-0 w-full flex flex-col items-center justify-center'} >
      <div className={'h-full md:h-auto relative bg-white p-4 shadow text-xs my-8 w-full max-w-screen-sm md:max-w-lg'} style={annotationStyle}>
        <div className={'overflow-y-auto h-full top-0 bottom-0 absolute md:relative py-8 pr-4 md:p-0'}>
          {children}
        </div>
        <button className={'absolute top-0 right-0 text-lg -mt-2 -mr-2 bg-white rounded-full w-6 h-6 flex items-center justify-center'} onClick={close}>
          <i className="fas fa-times-circle"></i>
        </button>
      </div>
    </div>
  )
}

export default throughPortal(Annotation)

// render as direct body child
function throughPortal(Comp) {
  return typeof document !== "undefined" 
    ? (props) => createPortal(<Comp {...props} />, document.body)
    : Comp
}
