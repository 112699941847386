import React from "react"
import { Link } from "gatsby"

const MenuItem = ({ i, p, selected }) => {
  const onClick = (ref) => {
    const menu = document.getElementById("menu")
    window.setTimeout(() => {
      menu.checked = false
    }, 800)
  }

  return (
    <li
      key={p.slug}
      className={`${String(p.slug).length} md:h-28 mb-10 md:mb-0 text-center 
        ${
          p.pageType === "longread"
            ? "text-base uppercase md:normal-case md:text-md"
            : ""
        } menu__list-item ${
        p.published ? "" : "text-gray-500 mix-blend-multiply"
      }${
        selected.slug === p.slug ||
        String(selected.id).split(".")[0] === String(p.id)
          ? " selected"
          : ""
      } ${p.bodyClass.includes("hide-in-desktop-menu") ? "md:hidden" : ""}`}
    >
      <div className={"h-full flex md:w-full shake-trigger"}>
        <Link
          title={p.menuTitle || p.title}
          className={"w-full menu__link flex flex-col"}
          to={`/${p.slug}`}
          target="_self"
          activeClassName="is-active"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          <div
            className={
              "h-full w-full shake-rotate flex flex-col items-start md:pl-6 justify-center w-38 text-left md:text-md"
            }
          >
            <span
              className={`${
                String(p.title).length >= 19 ? "w-40 md:w-48 " : "w-40 md:w-32"
              }`}
            >
              {p.title}
            </span>
          </div>
        </Link>
      </div>
    </li>
  )
}

export default MenuItem
