import React from "react"
import { Link } from "gatsby"
import DekoderLink from "./dekoder-link"

// important: wrap children in span + add &nbsp; ({"\u00A0"}) 
// to prevent :after element from wrapping to next line
// see links.scss for details

function linkTransformer(node, i, { classList, props, children }) {
  // transform internal links starting with a "/" (e.g. "/slug") to Gatsby-links
  if (node.name === "a" && node.attribs.href && node.attribs.href.match(/^\//)) {
    return <Link to={node.attribs.href}><span>{children}</span>{"\u00A0"}</Link>
  }
  // dekoder links
  else if (node.name === "a" && node.attribs.href && node.attribs.href.match(/https:\/\/www.dekoder.org\//)) {
    return <DekoderLink href={node.attribs.href}><span>{children}</span>{"\u00A0"}</DekoderLink>
  }
  // all other
  else if (node.name === "a") {
    return <a {...props}><span>{children}</span>{"\u00A0"}</a>
  }
}

export default linkTransformer
