import React from "react"
import Figure from "../figure/figure"

// transform new WP 5.9 galleries to old-school ones

export function galleryTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-gallery") && classList.includes("has-nested-images")) {
    const figureChildren = children.filter(c => c.type === Figure)
    const otherChildren = children.filter(c => c.type !== Figure)
    return (
      <figure className={classList.join(" ")}>
        <ul className="blocks-gallery-grid">
          {figureChildren.map((c, i) => (<li className="blocks-gallery-item" key={i}>{c}</li>))}
        </ul>
        {otherChildren}
      </figure>
    )
  }
}