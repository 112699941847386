import React, {useState, useMemo, useEffect, useRef} from "react"
import {Link} from "gatsby";
import {scrollTo} from "../footnote/footnote";

const ScrollToTop = ({}) => {
  const topDistance = 7000;

  const ref = useRef();

  const onClick = (e) => {
    e.preventDefault()
    scrollTo(`#top`)
  }

  useEffect(() => {
    const onScroll = ev => {
      let y = window.scrollY;

      if (y <= topDistance) {
        ref.current.classList.remove('opacity-100');
        ref.current.classList.add('opacity-0');
      } else {
        ref.current.classList.remove('opacity-0');
        ref.current.classList.add('opacity-100');
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [])

  return (
    <div ref={ref}
         className={"fixed transition-all duration-1500 ease-in-out bottom-0 w-full z-2 flex items-center justify-center opacity-0 h-0"}
         style={{
           bottom: "4rem"
         }}>
      <button
        className={"outline-none focus:outline-none rounded-full bg-opacity-70 block bg-orange2 hover:bg-black hover:opacity-100 hover:text-white w-16 h-16 flex items-center justify-center text-md"}
        onClick={onClick}
        aria-label="scroll to top"
      >
        <i className="fas fa-chevron-up"></i>
      </button>
    </div>
  )
}

export default ScrollToTop
