import React from "react"
import Annotation from "./annotation"
import AnnotationLink from "./annotation-link"

function annotationTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-annotation")) {
    const { id } = node.attribs
    return <Annotation annotationId={id}>{children}</Annotation>
  }
  else if (node.name === "a" && node.attribs.href && node.attribs.href.match(/annotation:/)) {
    const id = node.attribs.href.split(":")[1]
    return <AnnotationLink annotationId={id}>{children}</AnnotationLink>
  }
}

export default annotationTransformer