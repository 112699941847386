import React from "react"
import { SCROLL_PX_PER_FRAME_DEFAULT } from "./scroll-logic"

const overlayStyle = {
  top: "48px",
}

const ContentOverlay = ({
  children,
  startframe,
  className /*, endframe */,
}) => {
  const top = startframe * SCROLL_PX_PER_FRAME_DEFAULT
  return (
    <div
      className={`${className} scroll-video__overlay relative h-auto flex flex-col items-end justify-center`}
      style={{
        ...overlayStyle,
        top: `${top}px`,
      }}
    >
      {children}
    </div>
  )
}

export default ContentOverlay

/**
 *  // for fixed positioning: display, when currFrame is between start- and endframe
 *  function useIsActive(startframe, endframe) {
 *    const currFrame = useContext(CurrFrameContext)
 *    const isActive = currFrame > startframe * 1 && currFrame < endframe
 *    return isActive
 *  }
 */
