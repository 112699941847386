import React from "react"
import { useTransformer } from "../../modules/transformer"
import { usePreloadedPopups } from "gatsby-plugin-popup-preloader"
import { usePreview } from "gatsby-source-dek-wp"
import ArticleSections from "../article-sections/article-sections"
import { withAnnotations } from "../annotation/annotation"

const Article = ({ pageContext, restApi }) => {
  const { content, popups, postId, pageType, title } = pageContext
  usePreloadedPopups(popups)
  const contentState = usePreview(content, postId, restApi)
  const transformedContent = useTransformer(contentState)
  return (
    <article className={"debug grid grid-cols-12 pb-32"}>
      {["longread", "about"].includes(pageType) && (
        <h1 className={"mt-24 mb-12"}>{title}</h1>
      )}
      <ArticleSections>{transformedContent}</ArticleSections>
    </article>
  )
}

export default withAnnotations(Article)
