import React, { useMemo, cloneElement } from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { v4 as uuidv4 } from "uuid"
import annotationTransformer from "../components/annotation/annotation.transformer"
import figureTransformer from "../components/figure/figure.transformer"
import footnoteTransformer from "../components/footnote/footnote.transformer"
import linkTransformer from "../components/links/link.transformer"
import scrollVideoTransformer from "../components/scroll-video/scroll-video.transformer"
import teaserBoxTransformer from "../components/teaser-box/teaser-box.transformer"
import { imageTransformer } from "gatsby-source-dek-wp"
import { galleryTransformer } from "../components/gallery/gallery.transformer"

let transformers = [
  scrollVideoTransformer,
  footnoteTransformer,
  annotationTransformer,
  teaserBoxTransformer,
  figureTransformer,
  linkTransformer,
  imageTransformer,
  galleryTransformer
]

const options = {
  replace: transform,
  trim: true, // remove "\n" whitespace
}

export function transform(node) {
  // node, i
  if (node.name === "style") return <></> // null
  if (node.type !== "tag") return
  const classList = nodeClassList(node)
  const props = attributesToProps(node.attribs)
  const { style } = props
  const children = getChildren(node)

  for (let x = 0; x < transformers.length; x++) {
    const t = transformers[x]
    const comp = t(node, 0, { classList, props, style, children })
    if (comp) return cloneElement(comp, { ...comp.props, key: uuidv4() })
  }
}

export function transformString(contentString = "") {
  return parse(contentString || "", options)
}

export function useTransformer(contentString = "") {
  const transformedContent = useMemo(() => transformString(contentString), [
    contentString,
  ])
  return transformedContent
}

function getChildren(node) {
  const c = domToReact(node.children, options)
  return React.Children.toArray(c)
}

function nodeClassList(node) {
  if (!node.attribs || !node.attribs.class) return []
  return node.attribs.class.split(" ")
}
