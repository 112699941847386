import React, { useRef, createContext, useContext } from "react"
import Menu from "../menu"
import {
  withAuth,
  ContentsContext,
  useAllContents,
  useContents,
  useLangs,
  LangContext,
  withWebpCheck,
  useBodyClass,
  WebpContext
} from "gatsby-source-dek-wp"
import Article from "../article"
import Footer from "../footer/footer"
import ScrollToTop from "../scroll-to-top/scroll-to-top"
import ReadingProgressBar from "../reading-progress-bar/reading-progress-bar"
// import SocialImage from "../social-image/social-image"

export const ThemeColorContext = createContext()

const App = ({ data, pageContext, metaData }) => {
  const { langId, slug, themeColor = "gray" } = pageContext
  const { categoryNames, restApi } = metaData
  const allContents = useAllContents(data, false, categoryNames)
  const [lang, allLangs] = useLangs(langId, allContents)
  const [localeContents, selected] = useContents(
    allContents,
    lang.id,
    slug,
    [],
    false
  )
  const ref = useRef()
  const noWebpSupport = useContext(WebpContext)
  useBodyClass(noWebpSupport ? "no-webp" : "")

  return (
    <LangContext.Provider value={lang}>
      <ContentsContext.Provider value={localeContents}>
        <ThemeColorContext.Provider value={themeColor}>
          <div className={"min-h-screen of-features"}>
            <Menu
              contents={localeContents}
              metaData={metaData}
              selected={selected}
              allLangs={allLangs}
            />
            <div
              id={"top"}
              className={"xl:container mx-auto px-4 bg-white min-h-screen"}
              ref={ref}
            >
              <ReadingProgressBar color={themeColor} refEl={ref.current} />
              <Article pageContext={pageContext} restApi={restApi} />
            </div>
            <ScrollToTop />
            <Footer data={metaData} />
          </div>
        </ThemeColorContext.Provider>
      </ContentsContext.Provider>
    </LangContext.Provider>
  )
}

export default withWebpCheck(withAuth(App))

/* <SocialImage pageContext={pageContext}/> */
