import React, { useMemo } from "react"
import { useImagePreload } from "./image-preload"
import { useScrollLogic } from "./scroll-logic"
import { useTrafficSaver } from "./traffic-saver"
import { useOffloadedImages } from "./offloaded-images"
import { LoadingStatus } from "./loading-status"

const ScrollVideo = ({
  imgProps = [],
  overlays,
  offloaded,
  startFrame,
  endFrame,
}) => {
  // const [n, buttons] = useTestButtons()
  const _imgProps = useSlicedArray(imgProps, startFrame, endFrame)
  const [__imgProps, scrollPxPerFrame] = useTrafficSaver(_imgProps)
  const newImgProps = useOffloadedImages(offloaded, __imgProps)
  const [containerRef, containerHeight, canvasRef, dimensions, inView] =
    useScrollLogic(newImgProps, scrollPxPerFrame)
  const loadPercent = useImagePreload(newImgProps, inView)
  const { width, height } = dimensions
  return (
    <div
      ref={containerRef}
      className={"scroll-video -mx-4 lg:-mx-6"}
      style={{
        height: `${containerHeight}px`,
      }}
    >
      {loadPercent < 100 && <LoadingStatus percent={loadPercent} />}
      <div className={"scroll-video__sticky sticky"}>
        {/* buttons */}
        <div
          className={
            "scroll-video__aspect aspect-h-1 aspect-w-1 md:aspect-h-9 md:aspect-w-16"
          }
        >
          <canvas
            className={
              "scroll-video__canvas w-full object-cover transition-opacity duration-1000"
            }
            ref={canvasRef}
            width={width}
            height={height}
            style={{
              opacity: loadPercent <= 80 ? 0 : 1,
            }}
          ></canvas>
        </div>
      </div>
      {overlays}
    </div>
  )
}

export default ScrollVideo

function useSlicedArray(arr, start = 0, end = 0) {
  return useMemo(() => arr.slice(start, end || arr.length), [arr, start, end])
}
