module.exports = {
  purge: {
    enabled: true,
    preserveHtmlElements: true,
    content: [
      './src/**/**/*.js',
      './src/**/**/*.scss',
      './node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss',
      './node_modules/@fortawesome/fontawesome-free/scss/solid.scss',
      './node_modules/@fortawesome/fontawesome-free/scss/brands.scss',
    ],
    options: {
      keyframes: true,
      safelist: [
        /^inset-/,
      ],
    },
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      'sm': '320px',
      'md': '960px',
      'lg': '1600px',
      'xl': '1600px',
    },
    colors: {
      red: '#FD1F01',
      blue1: '#1539F4',
      blue2: '#09BDF1',
      violette1: '#4D30BD',
      violette2: '#852785',
      violette3: '#BD1E4D',
      orange1: '#FFA611',
      orange2: '#FFBC11',
      turquoise1: '#2EBE97',
      turquoise2: '#13FFBF',
      smoke: '#ececec',
      white: '#FFFFFF',
      transparent: 'transparent',
      black: '#000000',
      gray: {
        100: '#F6F6F6',
        200: '#F0F0F0',
        300: '#E4E4E4',
        400: '#C7C7C7',
        500: '#A5A5A5',
        600: '#777777',
        700: '#555555',
        800: '#3D3D3D',
        900: '#302F2F',
      },
    },
    fontFamily: {
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      display: ['"Maison Bold"', 'HelveticaBold', 'Arial', 'sans-serif'],
      sans: ['"Factor A Variable"', 'Helvetica', 'Arial', 'sans-serif'],
      icon: ['"Font Awesome 5 Free"'],
      mono: ['"Courier"', 'Monaco', 'Menlo', 'Consolas', '"Liberation Mono"', 'monospace',],
    },
    fontSize: {
      '3xs': '0.625em',   // 10px
      '2xs': '0.75em',    // 12px
      'xs': '1rem',       // 16px
      'sm': '1.125rem',   // 18px
      'base': '1.25rem',  // 20px
      'md': '1.5rem',     // 24px
      'lg': '1.875rem',   // 30px
      'xl': '2.5rem',     // 40px
      '2xl': '3.125rem',  // 50px
      '3xl': '3.75rem',   // 60px
      '4xl': '4.375rem',  // 70px
      '5xl': '5rem',      // 80px
      '6xl': '6.25rem',   // 100px
    },
    lineHeight: {
      'zero': '0',
      '50': '0.5',
      'none': '1',
      'tight': '1.045',
      'snug': '1.1',
      'normal': '1.2',
      'relaxed': '1.3',
      'loose': '1.4',
      'looser': '1.5',
    },
    letterSpacing: {
      zero: '-10px',
      tightest: '-.075em',
      tighter: '-.05em',
      tight: '-.025em',
      normal: '0',
      wide: '.01em',
      wider: '.02em',
      widest: '.1em',
    },
    zIndex: {
      '0': 0,
      '1': 1,
      '2': 2,
      '10': 10,
      '20': 20,
      '30': 30,
      '40': 40,
      '50': 50,
      '75': 75,
      '100': 100,
      'auto': 'auto',
    },
    extend: {
      transitionProperty: {
        'height': 'height',
        'spacing': 'margin, padding',
        'letterSpacing': 'letter-spacing',
        'lineHeight': 'lineHeight',
      },
      margin: {
        '38': '9.5rem'
      },
    },
    container: {
      padding: {
        DEFAULT: '0.75rem',
        md: '1.5rem',
      },
    },
  },
  variants: {
    extend: {
      margin: ['odd'],
      zIndex: ['group-hover'],
      padding: ['group-hover'],
      borderRadius: ['group-hover'],
      lineHeight: ['group-hover', 'hover'],
      transitionProperty: ['group-hover', 'hover', 'focus'],
      letterSpacing: ['hover', 'group-hover', 'focus'],
    },
  },
  plugins: [
    require("tailwindcss-hyphens"),
    require('@tailwindcss/aspect-ratio'),
    "gatsby-plugin-post-css"
  ],
}
