import React from "react"
import { useH2Subheadings } from "./h2-subheadings"
import { Navigation } from "./navigation"

const ArticleSections = ({ children }) => {
  const [patchedChildren, subheadings, currH2Index] = useH2Subheadings(children)
  return (
    <div className={"grid grid-cols-12"}>
      {patchedChildren}
      <Navigation
        subheadings={subheadings}
        currH2Index={currH2Index}
      />
    </div>
  )
}

export default ArticleSections
