import dekoderLogo from "./src/images/logos/dekoder-black.svg"
import dekoderLogoSlogan from "./src/images/logos/dekoder-black-slogan.svg"

export const socialImgDefault = {
  de:
    "https://specials.dekoder.org/content/uploads/2021/12/dissident_social_de.jpg",
  ru:
    "https://specials.dekoder.org/content/uploads/2021/05/dissident_social_ru2.png",
};

export { metaInfos, siteMetadata, restApi, categoryNames } from "./meta-data-node"

export const siteLogos = [
  { 
    src: dekoderLogo, 
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]"
    }
  },
]


export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Читая Европу"
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://www.dekoder.org/",
      ru: "https://www.dekoder.org/ru"
    },
    logo: dekoderLogoSlogan,
    scale: 0.75,
  }
]