import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useContext } from "react"
import { ContentsContext } from "gatsby-source-dek-wp"

const TeaserBox = ({ postId, title, kicker, anchor }) => {
  const contents = useContext(ContentsContext)
  const { slug } = contents.find((c) => c.postId === postId) || {}
  return (
    <div className={"mt-16"}>
      <AnchorLink
        to={`/${slug}` + (anchor ? `#${anchor}` : "")}
        title={title}
      >
        <span className={"inline-block text-2xs font-sans uppercase mb-4 "}>
          {kicker}
        </span>
        <h3>
          <span className={"underline"}>{title}</span>
          <br />
        </h3>
        <span className={"h1"}>→</span>
      </AnchorLink>
    </div>
  )
}

export default TeaserBox
