import { useState, useMemo, useEffect, useRef } from "react"
import { throttle } from "throttle-debounce"
import { useIntersectionObserver } from "gatsby-source-dek-wp"

const SCROLL_THROTTLE = 10 // higher value = less CPU & less smooth playback
const HEADER_HEIGHT = 48
const DEFAULT_DIMENSIONS = { width: 1440, height: 810 }
const PRELOAD_THRESHOLD = "3000px" // higher value = scroll video preloading is triggered earlier
export const SCROLL_PX_PER_FRAME_DEFAULT = 40

export function useScrollLogic(
  imgProps,
  scrollPxPerFrame = SCROLL_PX_PER_FRAME_DEFAULT
) {
  const [dimensions, setDimensions] = useState(DEFAULT_DIMENSIONS)
  const [containerRef, inView] = useIntersectionObserver({
    rootMargin: `${PRELOAD_THRESHOLD} 0px`, // extra space for preloading
  })
  // eslint-disable-next-line no-unused-vars
  const [currFrame, setCurrFrame] = useState(0)
  const canvasRef = useRef()
  const totalFrames = imgProps.length
  const containerHeight = useMemo(
    () => totalFrames * scrollPxPerFrame,
    [totalFrames, scrollPxPerFrame]
  )
  useEffect(() => {
    if (!inView) return
    const ctx = canvasRef.current.getContext("2d")
    const img = new Image()
    img.src = imgProps[0]?.src
    img.onload = function () {
      const { width, height } = this
      if (dimensions.width !== width || dimensions.height !== height)
        setDimensions({ width, height })
      else {
        ctx.drawImage(img, 0, 0)
        ctx.imageSmoothingEnabled = false
      }
    }
    const updateImg = (url) => (img.src = url)
    const onScroll = () => {
      if (!containerRef.current) return
      const scrollPos = window.pageYOffset
      const containerPos = containerRef.current.offsetTop
      const maxScroll = containerHeight - window.innerHeight - 2 * HEADER_HEIGHT
      const percent = Math.min(
        Math.max((scrollPos - containerPos) / maxScroll, 0),
        1
      )
      const imgNumber = Math.round(percent * (totalFrames - 1))
      updateImg(imgProps[imgNumber]?.src)
      setCurrFrame(imgNumber)
    }
    const throttledOnScroll = throttle(SCROLL_THROTTLE, onScroll)
    window.addEventListener("scroll", throttledOnScroll)
    return () => {
      window.removeEventListener("scroll", throttledOnScroll)
    }
  }, [
    containerRef,
    canvasRef,
    containerHeight,
    inView,
    totalFrames,
    imgProps,
    dimensions,
  ])
  return [containerRef, containerHeight, canvasRef, dimensions, inView]
}
