import React, {useContext} from "react"
import styled from "styled-components"
import {ThemeColorContext} from "../app"
import {WaveTopBottomLoading} from "react-loadingg"

const Wrapper = styled.div`
  position: absolute;
  top: 40vh;
  width: 100%;
  margin: 0 auto;
  z-index: 3;
  text-align: center;
  color: ${p => p.color};
`

export const LoadingStatus = ({percent}) => {
  const spinnerColor = useContext(ThemeColorContext) || "white"
  const textColor = spinnerColor === "white" ? "white" : "black"
  return (
    <Wrapper color={textColor}>
      <div className={"font-display mx-auto relative"}>
         <span
           className={"flex items-center justify-center flex-row-reverse"}
         >
           <span className={"ml-4 text-gray-300 text-2xs"}>
            {percent}%
           </span>
           
           <WaveTopBottomLoading
             style={{
               position: "relative",
               margin: 0,
               display: "inline-flex",
             }}
             color={spinnerColor}/>
           
           <span
             style={{
               letterSpacing: percent === 0 ? "-5px" : -5 + (percent / 5) + "px",
             }}
             className={"mr-4"}>
             loading
           </span>
         
         </span>
      </div>
    </Wrapper>
  )
}
