import React from "react"
import { scrollTo } from "./footnote"

const FootnoteLink = ({ children, footnoteId }) => {
  const onClick = (e) => {
    e.preventDefault()
    scrollTo(`#footnote-${footnoteId}`)
  }
  return (
    <a
      id={`footnote-link-${footnoteId}`}
      href={`#footnote-${footnoteId}`}
      onClick={onClick}
    >
      <sup>{children}</sup>
    </a>
  )
}

export default FootnoteLink
