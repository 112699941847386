import React from "react"
import TeaserBox from "./teaser-box"

function teaserBoxTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-internal-teaser")) {
    const { title, kicker, anchor } = node.attribs
    const id = children.find(c => c?.props?.hasOwnProperty("data-post-id")).props["data-post-id"] * 1
    return <TeaserBox postId={id} title={title} kicker={kicker} anchor={anchor} />
  }
}

export default teaserBoxTransformer