import React, { useContext, useEffect, useState, useMemo } from "react"
import Annotation, { AnnotationsContext } from "../annotation/annotation"
import { popupAggregator } from "gatsby-plugin-popup-preloader"
import { useTransformer } from "../../modules/transformer"
import { v4 as uuidv4 } from "uuid"

const DekoderLink = ({ children, href }) => {
  const [, setCurrAnnotationId] = useContext(AnnotationsContext)
  const [dekPopup, type] = useDekoderPopup(href)
  const uid = useUid()
  const onClick = (e) => {
    if (!dekPopup) return
    e.preventDefault()
    setCurrAnnotationId(uid)
  }
  return (
    <>
      <a
        href={href}
        onClick={onClick}
        className={`${!!dekPopup ? "has-dekoder-popup" : ""} ${type}`}
      >
        {children}
      </a>
      <Annotation annotationId={uid}>{dekPopup}</Annotation>
    </>
  )
}

export default DekoderLink

function useDekoderPopup(href) {
  const existingPopup = popupAggregator.getPopupByUrl(href)
  const [popupProps, setPopupProps] = useState({ ...existingPopup } || {})

  useEffect(() => {
    let isMounted = true
    const getPopupData = async () => {
      await delay(200)
      const { type, text, title } = await popupAggregator.addByUrl(href)
      if (isMounted) setPopupProps({ type, text, title, url: href })
    }
    getPopupData()
    return () => (isMounted = false)
  }, [href])

  const { title, text, type, url } = popupProps
  const popupText = useTransformer(text)
  const popupContent = !text ? null : (
    <>
      {!!title && <p><strong>{title}</strong></p>}
      {popupText}
      {["article", "gnose"].includes(type) && (
        <p>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <span>Mehr dazu auf dekoder.org</span>{"\u00A0"}
          </a>
        </p>
      )}
    </>
  )
  return [popupContent, type]
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function useUid() {
  return useMemo(uuidv4, [])
}

