import React from "react"
import scrollIntoView from "scroll-into-view"

const liStyleHidden = { height: 0, opacity: 0, margin: 0 }

export const Navigation = ({ subheadings, currH2Index }) => {
  function jumpTo(index) {
    const h2 = subheadings[index]
    scrollIntoView(h2.ref.current, { time: 300 })
  }
  const upArrowShown = currH2Index >= 1
  const downArrowShown = subheadings.length > currH2Index + 1
  return (
    <nav className={"mix-blend-multiply fixed h-full right-0 top-0 mr-2 w-2"}>
    <ul
      className={
        "flex h-full flex-col justify-center list-none font-display w-0 text-right right-0 pr-4"
      }
    >
      <li
        className={"mb-10 h-6 transition-all duration-2500 ease-in-out"}
        style={upArrowShown ? {} : liStyleHidden}
      >
        <button
          className={"outline-none focus:outline-none"}
          onClick={() => jumpTo(currH2Index - 1)}
        >
          ↑
        </button>
      </li>
      {subheadings.map((h2, index) => {
        const isShown = isNeighbour(
          index,
          Math.max(currH2Index, 0),
          subheadings.length - 1
        )
        const isActive = index === currH2Index
        const aClass = `absolute right-0 mr-4 pr-8 group-hover:pr-4 group-hover:bg-white px-2 group-hover:bg-opacity-50 hover:underline ${
          isActive ? "text-black" : "text-gray-600"
        }`
        return (
          <li
            className={
              "shake-rotate leading-zero hover:leading-normal whitespace-nowrap relative bg-transparent mb-10 h-6 w-28 pr-6 -ml-16 flex flex-col items-center justify-center group tracking-zero hover:tracking-normal hover:leading-loose leading-zero transition-all duration-2500 ease-in-out cursor-pointer"
            }
            style={isShown ? {} : liStyleHidden}
            key={index}
          >
            <a
              className={aClass}
              href={`#${h2.props.id}`}
              onClick={(e) => {
                e.preventDefault()
                jumpTo(index)
              }}
            >
              {h2.props.children}
            </a>
          </li>
        )
      })}
      <li
        className={"mb-10 h-6 transition-all duration-2500 ease-in-out"}
        style={downArrowShown ? {} : liStyleHidden}
      >
        <button
          className={"outline-none focus:outline-none"}
          onClick={() => jumpTo(currH2Index + 1)}
        >
          ↓
        </button>
      </li>
    </ul>
  </nav>
  )
}

function isNeighbour(checkIndex, baseIndex, lastIndex) {
  const isFirstOrLast = baseIndex === 0 || baseIndex === lastIndex
  const maxDistance = isFirstOrLast ? 2 : 1
  return Math.abs(checkIndex - baseIndex) <= maxDistance
}