import React from "react"
import Footnote from "./footnote"
import FootnoteLink from "./footnote-link"

function footnoteTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-footnote")) {
    const id = node.attribs.id * 1
    return <Footnote footnoteId={id}>{children}</Footnote>
  }
  else if (node.name === "sup") {
    const id = children[0]
    return <FootnoteLink footnoteId={id}>{children}</FootnoteLink>
  }
}

export default footnoteTransformer