import React, { useContext } from "react"
import { ContentsContext, LangContext } from "gatsby-source-dek-wp"
import { Link } from "gatsby"
import dekoderLogo from "../../images/logos/dekoder.svg"
import researchLogo from "../../images/logos/forschungsstelle-osteuropa.svg"
import universityLogo from "../../images/logos/university-basel.svg"

const Footer = ({ data }) => {
  const contents = useContext(ContentsContext)
  const aboutPage = contents.find((c) => c.pageType === "about")
  const lang = useContext(LangContext)
  return (
    <footer className={"bg-gray-800"}>
      <div
        className={"px-4 md:container mx-auto py-12 text-white font-display"}
      >
        <div
          className={
            "grid grid-cols-1 md:grid-cols-5 gap-y-12 md:gap-x-6 my-12"
          }
        >
          <div className={"col-span-1 flex items-center justify-start"}>
            <a
              className={"hover:opacity-70"}
              href="https://www.dekoder.org"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={dekoderLogo} alt="{data.siteMetaInfo.de.name}" />
            </a>
          </div>

          <div className={"col-span-1 flex items-center justify-start"}>
            <a
              className={"hover:opacity-70"}
              href="https://www.forschungsstelle.uni-bremen.de"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={researchLogo} alt="Forschungsstelle Osteuropa" />
            </a>
          </div>

          <div className={"col-span-1 flex items-center justify-start"}>
            <a
              className={"hover:opacity-70"}
              href="https://www.unibas.ch"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={universityLogo} alt="Universität Basel" />
            </a>
          </div>
        </div>

        <ul className={"text-xs mt-48"}>
          {!!aboutPage && (
            <li className="my-4">
              <Link
                to={`/${aboutPage.slug}`}
                className={"underline hover:opacity-50"}
              >
                {aboutPage.title}
              </Link>
            </li>
          )}
          {(data.metaInfos.find(m => m.langId === lang.id)?.info ?? []).map((link, i) => (
            <li className={"my-4"} key={i}>
              <a
                href={link.url}
                className={"underline hover:opacity-50"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
