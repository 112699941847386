import React, { useState, useEffect } from "react"
import { debounce } from "throttle-debounce"
import styled from "styled-components"

const ProgressBar = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 0%;
  width: 5px;
  background-color: gray; /* fallback */
  transform: translate3d(0, 0, 0);
`

function ReadingProgressBar({ refEl, color }) {
  const percent = useScrollPercent(refEl)
  return (
    <ProgressBar style={{ height: `${percent}%`, backgroundColor: color }} />
  )
}

export default ReadingProgressBar

function useScrollPercent(refEl) {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    if (!refEl) return
    const debouncedOnScroll = debounce(5, () => {
      const scrollPos = document.scrollingElement.scrollTop - refEl.offsetTop
      const max = refEl.scrollHeight - window.innerHeight
      const newPercent = Math.round((scrollPos / max) * 1000) / 10
      setPercent(Math.max(Math.min(newPercent, 100), 0))
    }, { atBegin: true })
    document.addEventListener("scroll", debouncedOnScroll) // scrollWrapper
    return () => document.removeEventListener("scroll", debouncedOnScroll)
  }, [refEl])
  return percent
}
