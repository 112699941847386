import React, { useContext } from "react"
import { AnnotationsContext } from "./annotation"

const AnnotationLink = ({ children, annotationId }) => {
  const [, setCurrAnnotationId] = useContext(AnnotationsContext)
  const onClick = (e) => {
    e.preventDefault()
    setCurrAnnotationId(annotationId)
  }
  return (
    <a href="#" onClick={onClick}>
      <span>{children}{"\u00A0"}</span>
    </a>
  )
}

export default AnnotationLink
